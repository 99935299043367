import React, { useEffect } from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import HeroImage from "../components/HeroImage/HeroImage";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Subheading from "../components/Subheading/Subheading";
import Slab from "../components/Slab";

import heroBox from "../images/hepatitis-b-treatment/hero-box.svg";
import heroBoxWithoutAnimation from "../images/hepatitis-b-treatment/hero-box-without-animation.svg";
import checkup from "../images/icons/checkup.svg";
import ShadedList, {
  ShadedListItem,
} from "../components/ShadedList/ShadedList";
import References from "../components/References/References";
import Paragraph from "../components/Paragraph";
import Strong from "../components/Strong";
import BlockQuote from "../components/BlockQuote/BlockQuote";

import { ReactComponent as DecreaseIcon } from "../images/icons/circle-down.svg";
import { ReactComponent as BanIcon } from "../images/icons/ban.svg";
import { ReactComponent as MortarIcon } from "../images/icons/mortar.svg";
import { ReactComponent as PlusIcon } from "../images/icons/plus-outline.svg";
import CourseOfInfection from "../images/hepatitis-b-treatment/4-0-course-of-infection.svg";
import Card from "../components/Card/Card";
import NextPage from "../components/Link/NextPage";
import AssetCardGroup from "../components/AssetCard/AssetCardGroup";
import ScreeningGuidelines from "../partials/AssetCards/ScreeningGuidelines";
import CodeFinder from "../partials/AssetCards/CodeFinder";
import ButtonSecondary from "../components/Buttons/ButtonSecondary";
import TreatmentGuidelinesTable from "../partials/Tables/TreatmentGuidelinesTable/index";
import AbbrList from "../components/Abbr/AbbrList";
import Abbr from "../components/Abbr/Abbr";
import Footnotes from "../components/Footnotes";
import Link from "../components/Link/Link";
import ReferenceItem from "../components/References/ReferenceItem";
import tracker from "../tracker";
import ImageWrapper from "../components/ImageWrapper";
import Parallax from "../components/Parallax/Parallax";

const TreatingPatients = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hepatitis-b-treatment/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      slabPerson: file(
        relativePath: { eq: "hepatitis-b-treatment/4-0-slab4-person.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 184) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      footerMan: file(relativePath: { eq: "footer-man.png" }) {
        childImageSharp {
          fluid(maxWidth: 328) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      page: trackingJson(id: { eq: "hepatitis-b-treatment" }) {
        subject
      }
      findOutMore: trackingJson(id: { eq: "findOutMore" }) {
        ...TrackingQuery
      }
      managingChb: trackingJson(id: { eq: "managingChb" }) {
        ...TrackingQuery
      }
      downloadTheGuide: trackingJson(id: { eq: "downloadTheGuide" }) {
        ...TrackingQuery
      }
      downloadTheList: trackingJson(id: { eq: "downloadTheList" }) {
        ...TrackingQuery
      }
    }
  `);
  useEffect(() => {
    window.dataLayer.push(data.page);
  }, []);

  return (
    <Layout>
      <SEO
        title="Chronic Hepatitis B Treatment"
        description="Review AASLD, AATA, USTA and EASL chronic hepatitis B treatment guidelines, and access communication tools to counsel patients on hepatitis B treatment goals."
      />
      <HeroImage
        headLine="Treating your chronic hep&nbsp;B patients."
        content={
          <span>
            Once diagnosed, chronic hep&nbsp;B (CHB) patients should be
            evaluated to determine the phase of infection and eligibility for
            treatment, according to accepted guidelines.<sup>1-3</sup>
          </span>
        }
        fluidQuery={data.hero.childImageSharp.fluid}
        boxSrc={heroBox}
        boxSrcWithoutAnimation={heroBoxWithoutAnimation}
        imagePosition={{ desktop: { x: 70, y: 60 }, mobile: { x: 72, y: 50 } }}
      />
      <div className="pt-6 md:pt-16 pb-8">
        <div className="container">
          <div className="md:flex">
            <div className="w-full md:w-3/4 pb-8">
              <Subheading>
                Initial CHB patient pre-treatment evaluation<sup>1-4</sup>
              </Subheading>
              <Paragraph>
                The initial evaluation should include a thorough history and
                physical examination, followed by laboratory tests.
              </Paragraph>
            </div>
            <div className="w-full md:w-1/4">
              <img className="mx-auto" src={checkup} />
            </div>
          </div>
          <div className="md:flex md:pb-4 pt-4">
            <div className="w-full md:w-1/2 py-4">
              <h3 className="font-bold antialiased h-16">
                History and physical examination to assess:
              </h3>
              <div className="pt-8 md:pt-0">
                <ShadedList>
                  <ShadedListItem>
                    <b>Risk factors for viral hepatitis</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Family history of HCC</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Route of transmission</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>History of alcohol use</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Presence of comorbid diseases</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Duration of infection</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Risk factors for HIV coinfection</b>
                  </ShadedListItem>
                </ShadedList>
              </div>
            </div>
            <div className="md:border-l md:border-grey-dark ml-8 pr-8"></div>
            <div className="w-full md:w-1/2 pt-4">
              <h3 className="font-bold antialiased h-16">
                Laboratory tests may include:
              </h3>
              <div className="pt-8 md:pt-0">
                <ShadedList>
                  <ShadedListItem>
                    <b>Serial testing for HBV DNA and ALT</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>HBeAg and anti-HBe</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>LFTs, including CBC with platelets, PLT, HFP, and PT</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>
                      HCC screening: MRI (preferred), computed tomography, AFP,
                      or ultrasound
                    </b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Urinalysis</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>HBV genotype</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>Tests for antibodies to HAV, HCV, HDV, and HIV</b>
                  </ShadedListItem>
                  <ShadedListItem>
                    <b>
                      Transient elastography or liver biopsy<sup>a </sup>
                    </b>
                  </ShadedListItem>
                </ShadedList>
              </div>
            </div>
          </div>
          <AbbrList>
            <Abbr term="ALT" title="alanine aminotransferase" />
            <Abbr term="Anti-HBe" title="antibody to HBeAg" />
            <Abbr term="CBC" title="complete blood count" />
            <Abbr term="HBeAg" title="hepatitis B e-antigen" />
            <Abbr term="HBsAg" title="hepatitis B surface antigen" />
            <Abbr term="HBV" title="hepatitis B virus" />
            <Abbr
              term="HBV DNA"
              title="hepatitis B virus
          deoxyribonucleic acid"
            />
            <Abbr term="HCC" title="hepatocellular carcinoma" />
            <Abbr term="HCV" title="hepatitis C virus" />
            <Abbr term="HDV" title="hepatitis D virus" />
            <Abbr term="HIV" title="human immunodeficiency virus" />
            <Abbr
              term="HFP"
              title="hepatic function
          panel"
            />
            <Abbr term="LFT" title="liver function tests" />
            <Abbr term="PLT" title="platelet" />
            <Abbr term="PT" title="prothrombin time" />
          </AbbrList>
          <Paragraph className="text-xs">
            <sup>a</sup>Liver biopsy is optional for patients indicated for
            treatment, but may be helpful in those with normal ALT and &gt;35-40
            years of age.<sup>4</sup>
          </Paragraph>
        </div>
      </div>
      <Slab className="bg-grey">
        <div className="md:flex">
          <div className="md:w-1/2 mr-4">
            <Subheading className="w-full">
              Identifying the phase of HBV infection
            </Subheading>
            <Paragraph>
              Chronic hep B follows a variable clinical course–
              <Strong>not all patients will go through each phase</Strong>{" "}
              (including resolution).<sup>4</sup>
            </Paragraph>
            <Paragraph>
              <Strong>Regular testing</Strong>, including HBeAg status, ALT, and
              HBV DNA will help identify the patient's current phase of
              infection and determine the required course of action
              <sup>1,4</sup>
            </Paragraph>
            <Paragraph>
              While treatment is not always indicated,{" "}
              <Strong>
                patients will require lifelong monitoring<sup>5</sup>
              </Strong>
            </Paragraph>
          </div>
          <div className="md:w-1/2">
            <Paragraph className="uppercase pt-4 md:pt-0 pb-2">
              Natural course of HBV infection
            </Paragraph>
            <ImageWrapper left maxWidth={500}>
              <img
                src={CourseOfInfection}
                alt="Graphic showing the natural course of HBV infection"
              />
            </ImageWrapper>
            <Paragraph className="text-xs">
              Figure adapted from Tong MJ, et al.<sup>6</sup>
              <br />
              Terminology in parenthesis is from the EASL 2017 guidelines.
              <sup>3</sup>
            </Paragraph>
            <AbbrList>
              <Abbr
                term="EASL"
                title="European assocation for the study of the Liver"
              />
              <Abbr term="HBsAG" title="hepatitis B surface antigen." />
            </AbbrList>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey-medium">
        <div className="md:flex">
          <div className="md:w-3/5">
            <Subheading>CHB Treatment goals</Subheading>
            <div className="py-4">
              <DecreaseIcon />
              <Paragraph>
                <Strong size="lg" color="black-dark" className="block">
                  Decrease morbidity and mortality
                </Strong>
                related to CHB
                <sup>2</sup>
              </Paragraph>
            </div>
            <div className="py-4">
              <BanIcon />
              <Paragraph>
                <Strong color="black-dark" size="lg" className="block">
                  Prevent progression
                </Strong>
                of CHB to cirrhosis, end-stage liver disease and HCC<sup>6</sup>
              </Paragraph>
            </div>
          </div>
          <div className="md:w-2/5">
            <BlockQuote
              attribution={
                <span>
                  -WHO guidelines<sup>7</sup>
                </span>
              }
              padded
              rqBottom={20}
            >
              &ldquo;Antiviral agents active against HBV are available, and
              haves been shown to suppress HBV replication, prevent progression
              to cirrhosis, and reduce the risk of HCC and liver-related
              deaths.&rdquo;
            </BlockQuote>
          </div>
        </div>
      </Slab>
      <Slab>
        <Subheading className="w-full">
          Overview of AASLD, AATA, EASL, and USTA CHB treatment guidelines and
          algorithms
        </Subheading>
        <Paragraph>
          Given the dynamic nature of chronic hepatitis B infection, ongoing
          monitoring of HBV DNA and ALT levels is important to characterize the
          phase of infection and to ensure that treatment is initiated when
          necessary.
        </Paragraph>
        <Paragraph className="mt-5">
          Across current chronic hepatitis B guidelines, treatment initiation is
          guided by HBV DNA and ALT levels and/or the presence of cirrhosis.
          <sup>1,3,6</sup>
        </Paragraph>
        <TreatmentGuidelinesTable />
        <AbbrList className="pt-6">
          <Abbr
            term="AASLD"
            title="American Association for the Study of Liver Diseases"
          />
          <Abbr term="AATA" title="Asian American Treatment Algorithm" />
          <Abbr
            term="EASL"
            title="European Association for the Study of the Liver"
          />
          <Abbr term="ULN" title="upper limit of normal;" />
        </AbbrList>
        <Footnotes>
          <sup>b</sup>ULN criteria for men and women, respectively: AASLD 2018:
          35 U/L and 25 U/L; AATA 2018: local laboratory range; EASL 2017: ~40
          U/L for both.
          <br /> <sup>c</sup>Noninvasive testing showing significant fibrosis
          (&ge;F2) or liver biopsy showing moderate/severe inflammation (A2 or
          A3) and/or significant fibrosis (&ge;F2). <br />
          <sup>d</sup>Treatment can be considered for those >40 years of age,
          with a family history of cirrhosis or HCC, previous treatment history,
          or extrahepatic manifestations (presence of extrahepatic manifestation
          is an indication for treatment, independent of liver disease
          severity).
          <br />
          <sup>e</sup>Albumin &lt;3.5 g/dL, platelet count &lt;130,000/mm3,
          presence of basal core promoter mutation, HCC in first-degree
          relative, or elevated AFP in the absence of HCC. <br />
          <sup>f</sup>HBeAg+ CHB patients with persistently high HBV DNA and
          normal ALT may be treated if they are &gt;30 years old, regardless of
          the severity of liver histologic lesions. HBeAg+ or HBeAg− CHB
          patients with a family history of HCC or cirrhosis and extrahepatic
          manifestations can be treated even if typical treatment indications
          are not fulfilled. CHB patients with compensated or decompensated
          cirrhosis need treatment, with any detectable HBV DNA level and
          regardless of ALT levels.
        </Footnotes>
        <div className="flex flex-col md:flex-row pt-4">
          <div className="w-full md:w-3/4 pt-8 pb-0 md:py-8 lg:py-12">
            <Card className="bg-green">
              <div className="flex flex-col md:flex-row items-start md:items-center space-between">
                <MortarIcon className="my-4 md:my-0 w-24" />
                <Strong color="white" size="lg" className="px-4">
                  Long-term, if not indefinite, antiviral treatment is
                  recommended for many CHB patients<sup>4,8</sup>
                </Strong>
              </div>
            </Card>
          </div>
          <div className="hidden md:block md:w-1/4 z-0 -mb-64 md:px-4 lg:px-12">
            <Parallax speed={0.5} percentage={0.9}>
              <ImageWrapper maxWidth={184}>
                <Img fluid={data.slabPerson.childImageSharp.fluid} />
              </ImageWrapper>
            </Parallax>
          </div>
        </div>
      </Slab>
      <Slab className="bg-grey relative">
        <div className="md:flex">
          <div className="w-full md:w-7/12 md:pr-2">
            <Subheading className="w-full">
              3 points to discuss with your patients about CHB treatment
            </Subheading>
            <ol className="text-black list-decimal list-inside pt-4 pr-4">
              <li className="pb-2">
                <Strong>Provide effective counseling</Strong> and education
                about long-term effects of hepatitis B to help patients
                understand the severity of the disease
              </li>
              <li className="pb-2">
                <Strong>Follow up</Strong> with patients to ensure they
                understand the importance and goals of treatment
              </li>
              <li>
                <Strong>Inform patients</Strong> that treatment support programs
                may be available, if applicable
              </li>
            </ol>
          </div>
          <div className="w-full md:w-5/12">
            <Card className="bg-green">
              <div className="p-4">
                <PlusIcon />
                <Strong color="white" size="lg" className="py-4 block">
                  Interested in learning about a treatment option for your
                  patients with chronic Hep B?
                </Strong>
                <ButtonSecondary
                  to="https://www.vemlidyhcp.com"
                  external
                  isGilead
                  tracking={tracker(data.findOutMore)}
                >
                  Find out More
                </ButtonSecondary>
              </div>
            </Card>
          </div>
        </div>
      </Slab>
      <Slab>
        <NextPage
          to="/hepatitis-b-management"
          tracking={tracker(data.managingChb)}
        >
          Managing chronic hepatitis B
        </NextPage>
        <Subheading level={3}>
          Information for healthcare professionals
        </Subheading>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-3/4">
            <AssetCardGroup>
              <ScreeningGuidelines
                className="w-full lg:w-1/2"
                tracking={tracker(data.downloadTheGuide)}
              />
              <CodeFinder
                className="w-full lg:w-1/2"
                cta="Download the List"
                tracking={tracker(data.downloadTheList)}
              />
            </AssetCardGroup>
            <AbbrList>
              <Abbr term="ACP" title="American College of Physicians" />
              <Abbr
                term="CDC"
                title="Centers for Disease Control and Prevention"
              />
              <Abbr term="USPSTF" title="U.S. Preventive Services Task Force" />
              <Abbr term="WHO" title="World Health Organization." />
            </AbbrList>
            <References>
              <ReferenceItem>
                Terrault N, Lok A, McMahon B, et al. Update on prevention,
                diagnosis, and treatment of chronic hepatitis B: AASLD 2018
                hepatitis B guidance. <em>Hepatology</em>. 2018;67(4):1560-1599.
              </ReferenceItem>
              <ReferenceItem>
                Terrault N, Bzowej N, Chang K, et al. AASLD guidelines for
                treatment of chronic hepatitis B. <em>Hepatology</em>.
                2016;63:261-283.
              </ReferenceItem>
              <ReferenceItem>
                European Association for the Study of the Liver. EASL 2017
                clinical practice guidelines on the management of hepatitis B
                virus infection. <em>J Hepatol</em>. 2017;67(2):370-398.
              </ReferenceItem>
              <ReferenceItem>
                Martin P, Lau D, Nguyen M, et al. A treatment algorithm for the
                management of chronic hepatitis B virus infection in the United
                States: 2015 update. <em>Clin Gastroenterol Hepatol</em>.
                2015;13(12):2071-2087.e16.
              </ReferenceItem>
              <ReferenceItem>
                Centers for Disease Control and Prevention.{" "}
                <em>MMWR Morb Mortal Wkly Rep</em>. 2008;57:1-20.
              </ReferenceItem>
              <ReferenceItem>
                Tong M, Pan C, Han S, et al. An expert consensus for the
                management of chronic hepatitis B in Asian Americans.{" "}
                <em>Aliment Pharmacol Ther</em>. 2018;47(8):1181-1200.
              </ReferenceItem>
              <ReferenceItem>
                World Health Organization (WHO). Guidelines for the prevention,
                care and treatment of persons with chronic hepatitis B
                infection. Geneva, Switzerland: WHO Press; 2015.
              </ReferenceItem>
              <ReferenceItem>
                Papatheodoridis G, Vlachogiannakos I, Cholongitas E, et al.
                Discontinuation of oral antivirals in chronic hepatitis B: A
                systematic review. <em>Hepatology</em>. 2016;63:1481-149.
              </ReferenceItem>
            </References>
          </div>
          <div className="w-full md:w-1/4 self-end h-48 md:h-auto pt-8">
            <div className="z:0 -mb-64 md:-mb-16 mx-16 sm:mx-32 md:mx-0 md:ml-4">
              <ImageWrapper maxWidth={328}>
                <Img fluid={data.footerMan.childImageSharp.fluid} />
              </ImageWrapper>
            </div>
          </div>
        </div>
      </Slab>
    </Layout>
  );
};

export default TreatingPatients;
