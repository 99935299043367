import React, { FunctionComponent } from "react";

import { ReactComponent as LeftQuote } from "./left-quote.svg";
import { ReactComponent as RightQuote } from "./right-quote.svg";
import Paragraph from "../Paragraph";
import "./BlockQuote.scss";
import { mergeClassNames } from "../../helpers";
type Props = {
  cite?: string | JSX.Element;
  citeUrl?: string;
  attribution?: string | JSX.Element;
  padded?: boolean;
  rqBottom?: number;
  noIndent?: boolean;
};

const BlockQuote: FunctionComponent<Props> = ({
  children,
  cite,
  citeUrl,
  attribution,
  padded,
  rqBottom,
  noIndent,
}) => {
  return (
    <blockquote
      className={mergeClassNames(
        "bg-green max-w-xl rounded-lg",
        padded ? "py-12 pl-4 pr-12" : ""
      )}
      {...(citeUrl ? { cite: citeUrl } : {})}
    >
      <div className="relative">
        <LeftQuote className="absolute left-0 top-0" />
        <div
          className={mergeClassNames(
            "relative z-10 py-4",
            noIndent ? "" : "pl-4"
          )}
        >
          <Paragraph color="white">{children}</Paragraph>
          {attribution ? (
            <footer className="mt-6">
              <Paragraph color="white" className="font-medium">
                {attribution}
                {cite ? <cite className="italic">, {cite}</cite> : null}
              </Paragraph>
            </footer>
          ) : null}
        </div>
        <RightQuote
          className={mergeClassNames(
            "absolute right-0 bottom-0",
            `mb-${rqBottom || 0}`
          )}
        />
      </div>
    </blockquote>
  );
};

export default BlockQuote;
