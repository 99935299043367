import React, { FunctionComponent } from "react";
import MaybeClassProps from "../../../components/MaybeClassProps.type";
import Table, {
  TableRow,
  TableCell,
  TableHeader,
} from "../../../components/Table/Table";
import Strong from "../../../components/Strong";
import { mergeClassNames } from "../../../helpers";

const TreatmentGuidelinesTableMobile: FunctionComponent<MaybeClassProps> = ({
  className,
}) => {
  return (
    <>
      <Table striped className={mergeClassNames(className, "mt-8")}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            className="text-center border-t border-l border-r"
            colSpan={2}
          >
            <Strong size="base">HBeAg+</Strong>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow header>
          <TableHeader></TableHeader>
          <TableHeader className="text-left  border-l">
            HBV DNA (IU/mL)
          </TableHeader>
          <TableHeader className="text-left border-r">ALT (U/L)</TableHeader>
          <TableHeader spacer></TableHeader>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell>
            <Strong>
              AASLD 2018<sup>1</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">&gt;20,000</TableCell>
          <TableCell className="border-r">
            &ge;2×ULN<sup>b</sup> or significant liver disease
            <sup>c,d</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Strong>
              AATA 2018<sup>6</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">&gt;2000</TableCell>
          <TableCell className="border-r">
            &gt;ULN<sup>b</sup> or significant liver disease<sup>c</sup>
            /other risk factors<sup>e</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell>
            <Strong>
              EASL 2017<sup>3</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">&gt;2000</TableCell>
          <TableCell className="border-r">
            &gt;ULN<sup>b</sup>and/or evidence of liver disease
            <sup>c,f</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell></TableCell>
          <TableCell className="border-l">&gt;20,000</TableCell>
          <TableCell className="border-r">
            &gt;ULN<sup>b</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            colSpan={2}
            className="border-r border-l border-b"
          ></TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
      </Table>
      <Table striped className={mergeClassNames(className, "mt-8")}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            className="text-center border-t border-l border-r"
            colSpan={2}
          >
            <Strong size="base">HBeAg–</Strong>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow header>
          <TableHeader></TableHeader>
          <TableHeader className="text-left border-l">
            HBV DNA (IU/mL)
          </TableHeader>
          <TableHeader className="text-left border-r">ALT (U/L)</TableHeader>
          <TableHeader spacer></TableHeader>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell>
            <Strong>
              AASLD 2018<sup>1</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">&gt;2000</TableCell>
          <TableCell className="border-r">
            &ge;2×ULN<sup>b</sup> or significant liver disease
            <sup>c,d</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Strong>
              AATA 2018<sup>6</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">&gt;2000</TableCell>
          <TableCell className="border-r">
            &gt;2×ULN<sup>b</sup> or significant liver disease<sup>c</sup>
            /other risk factors<sup>e</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell>
            <Strong>
              EASL 2017<sup>3</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">&gt;2000</TableCell>
          <TableCell className="border-r">
            &gt;2×ULN<sup>b</sup> or significant liver disease
            <sup>c,f</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell></TableCell>
          <TableCell className="border-l">&gt;2000</TableCell>
          <TableCell className="border-r">
            &gt;2×ULN<sup>b</sup>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            colSpan={2}
            className="border-r border-l border-b"
          ></TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
      </Table>
      <Table striped className={mergeClassNames(className, "mt-8")}>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            className="text-center border-t border-l border-r"
            colSpan={2}
          >
            <Strong size="base">Cirrhosis (HBeAg±)</Strong>
          </TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow header>
          <TableHeader></TableHeader>
          <TableHeader className="text-left border-l">
            HBV DNA (IU/mL)
          </TableHeader>
          <TableHeader className="text-left border-r">ALT (U/L)</TableHeader>
          <TableHeader spacer></TableHeader>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell>
            <Strong>
              AASLD 2018<sup>1</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">Detectable</TableCell>
          <TableCell className="border-r">Any</TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Strong>
              AATA 2018<sup>6</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">Detectable</TableCell>
          <TableCell className="border-r">Any</TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell>
            <Strong>
              EASL 2017<sup>3</sup>
            </Strong>
          </TableCell>
          <TableCell className="border-l">Detectable</TableCell>
          <TableCell className="border-r">Any</TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow className="bg-grey">
          <TableCell></TableCell>
          <TableCell className="border-l"></TableCell>
          <TableCell className="border-r"></TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell
            colSpan={2}
            className="border-r border-l border-b"
          ></TableCell>
          <TableCell spacer></TableCell>
        </TableRow>
      </Table>
    </>
  );
};
export default TreatmentGuidelinesTableMobile;
