import React, { FunctionComponent } from "react";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

const TreatmentGuidelinesTable: FunctionComponent = () => {
  return (
    <>
      <Mobile className="block sm:hidden" />
      <Desktop className="hidden sm:block" />
    </>
  );
};
export default TreatmentGuidelinesTable;
