import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../helpers";

import "./Table.scss";

type TableProps = {
  shaded?: boolean;
  striped?: boolean;
  grid?: boolean;
  className?: string;
};

type RowProps = {
  header?: boolean;
  className?: string;
  short?: boolean;
  skipBottomBorder?: boolean;
};

type CellProps = {
  className?: string;
  column?: boolean;
  colSpan?: number;
  rowSpan?: number;
  skipBottomBorder?: boolean;
  spacer?: boolean;
};

const Table: FunctionComponent<TableProps> = ({
  children,
  className,
  grid,
  striped,
  shaded,
}) => {
  return (
    <table
      className={mergeClassNames(
        "Table",
        grid && "Table--grid",
        shaded && "Table--shaded",
        striped && "Table--striped",
        className
      )}
    >
      <tbody>{children}</tbody>
    </table>
  );
};

const TableRow: FunctionComponent<RowProps> = ({
  children,
  className,
  header,
  short,
  skipBottomBorder,
}) => {
  return (
    <tr
      className={mergeClassNames(
        "Table__Row",
        header && "Table__Row--header bg-red text-white",
        skipBottomBorder && "Table__Row--skip-bottom-border",
        short && "Table__Row--short",
        className
      )}
    >
      {children}
    </tr>
  );
};

const TableCell: FunctionComponent<CellProps> = ({
  children,
  className,
  column,
  colSpan,
  rowSpan,
  spacer,
  skipBottomBorder,
}) => {
  return (
    <td
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={mergeClassNames(
        "Table__Cell align-top",
        column && "Table__Cell--Column",
        skipBottomBorder && "Table__Cell--skip-bottom-border",
        "text-black",
        spacer && "Table__Cell--spacer",
        className
      )}
    >
      {children}
    </td>
  );
};
const TableHeader: FunctionComponent<CellProps> = ({
  children,
  className,
  column,
  colSpan,
  rowSpan,
  spacer,
}) => {
  return (
    <th
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={mergeClassNames(
        "Table__Header",
        column && "Table__Header--Column",
        spacer && "Table__Cell--spacer",
        className
      )}
    >
      <div className="relative z-10">{children}</div>
    </th>
  );
};

export default Table;
export { TableRow, TableCell, TableHeader };
